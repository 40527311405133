import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Index from "./view";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<Index />} path={"/"}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
