import {Button, Input, Radio, Table} from "antd";
import {useEffect, useState} from "react";
import {getDeviceData, getDeviceData2} from "../service/IndexService";

function Index(props) {

    const columns = [
        {
            title: 'IMEI',
            dataIndex: 'IMEI',
            key: 'IMEI',
            align: 'center'
        },
        {
            title: 'QCCID',
            dataIndex: 'QCCID',
            key: 'QCCID',
            align: 'center'
        },
        {
            title: 'CID',
            dataIndex: 'CID',
            key: 'CID',
            align: 'center'
        },
        {
            title: 'CSQ',
            dataIndex: 'CSQ',
            key: 'CSQ',
            align: 'center'
        },
        {
            title: 'Cycle',
            dataIndex: 'Cycle',
            key: 'Cycle',
            align: 'center'
        },
        {
            title: 'LAC',
            dataIndex: 'LAC',
            key: 'LAC',
            align: 'center'
        },
        {
            title: '压力',
            dataIndex: 'P',
            key: 'P',
            align: 'center'
        },
        {
            title: '温度',
            dataIndex: 'T',
            key: 'T',
            align: 'center'
        },
        {
            title: 'X',
            dataIndex: 'X',
            key: 'X',
            align: 'center'
        },
        {
            title: 'Y',
            dataIndex: 'Y',
            key: 'Y',
            align: 'center'
        },
        {
            title: 'Z',
            dataIndex: 'Z',
            key: 'Z',
            align: 'center'
        },
        {
            title: '接收时间',
            dataIndex: 'createTime',
            key: 'createTime',
            align: 'center'
        },
    ];


    const columns1 = [
        {
            title: '网关imei',
            dataIndex: 'maindeviceimei',
            key: 'maindeviceimei',
            align: 'center'
        },
        {
            title: '唯一识别号',
            dataIndex: 'subdeviceid',
            key: 'subdeviceid',
            align: 'center'
        },
        {
            title: 'Cycle',
            dataIndex: 'Cycle',
            key: 'Cycle',
            align: 'center'
        },
        {
            title: '压力',
            dataIndex: 'pressure',
            key: 'pressure',
            align: 'center',
            render(text) {
                return text == 65535 ? "异常" : text
            }
        },
        {
            title: '温度',
            dataIndex: 'temp',
            key: 'temp',
            align: 'center',
            render(text) {
                return text == 65535 ? "异常" : text
            }
        },
        {
            title: 'X',
            dataIndex: 'X',
            key: 'X',
            align: 'center',
            render(text) {
                return text == 65535 ? "异常" : text
            }
        },
        {
            title: 'Y',
            dataIndex: 'Y',
            key: 'Y',
            align: 'center',
            render(text) {
                return text == 65535 ? "异常" : text
            }
        },
        {
            title: 'Z',
            dataIndex: 'Z',
            key: 'Z',
            align: 'center',
            render(text) {
                return text == 65535 ? "异常" : text
            }
        },
        {
            title: '接收时间',
            dataIndex: 'createTime',
            key: 'createTime',
            align: 'center'
        },
    ];




    const [tableNum, setTableNum] = useState(false);

    const [dataSource, setDataSource] = useState([]);

    const [imei, setImei] = useState("");

    const [reloadKey, setReloadKey] = useState(+ new Date());

    const [total, setTotal] = useState(0)

    const [current, setCurrent] = useState(0)

    useEffect(() => {
        if (tableNum) {
            getTableData2()
        }
        else {
            getTableData()
        }
    }, [])

    async function getTableData(pageNo){
        try {
            if (!pageNo) {
                pageNo = 1
            }
            let res = await getDeviceData(imei, 15, pageNo);
            console.log(res.data.data);
            setTotal(res.data.data.total);
            setCurrent(pageNo)
            for (let i = 0; i < res.data.data.records.length; i ++) {
                if (res.data.data.records[i].P && res.data.data.records[i].P > 0.1) {
                    res.data.data.records[i].P = res.data.data.records[i].P - 0.1
                }
            }
            setDataSource(res.data.data.records);
            setReloadKey(+ new Date());
        }
        catch (e) {
            alert("获取数据失败");
        }
    }

    async function getTableData2() {
        try {
            let res = await getDeviceData2(imei);
            setDataSource(res.data.data);
            setReloadKey(+ new Date());
        }
        catch (e) {
            alert("获取数据2失败")
        }
    }

    function keyDown(e) {
        console.log(e);
        if (e.keyCode === 13) {
            if (tableNum) {
                getTableData2();
            }
            else {
                getTableData();
            }
        }
    }

    function onChange(e) {
        console.log(e);
        if (e.target.value) {
            console.log(e.target.value);
            getTableData2()
        }
        else {
            getTableData()
        }
        setTableNum(e.target.value)
    }

    function pageChange(pageNo) {
        if (!tableNum) {
            getTableData(pageNo)
        }
    }


    return (
        <div style={{padding: 20}}>
            <div style={{marginBottom: "10px"}}>
                <Radio.Group onChange={onChange} value={tableNum}>
                    <Radio value={false}>4G</Radio>
                    <Radio value={true}>Lora</Radio>
                </Radio.Group>
            </div>
            <div style={{marginBottom: "10px"}}>
                <Input onKeyDown={keyDown} onChange={(e) => {setImei(e.target.value)}} style={{width: "200px", marginRight: "20px"}} placeholder={tableNum ? "请输入唯一识别码" : "请输入imei"} />
                <Button onClick={() => {
                    if (tableNum) {
                        getTableData2();
                    }
                    else {
                        getTableData();
                    }
                }} type={"primary"}>搜索</Button>
            </div>
            <div>
                {
                    tableNum ?
                        <Table key={reloadKey} columns={tableNum ? columns1 : columns} dataSource={dataSource} pagination={{pageSize: 15, showSizeChanger: false}}/>
                        :
                        <Table key={reloadKey} columns={tableNum ? columns1 : columns} dataSource={dataSource} pagination={{pageSize: 15, showSizeChanger: false, onChange: pageChange, total: total, current: current}}/>
                }
            </div>
        </div>
    )
}


export default Index